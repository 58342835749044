.button {
    display: inline-block;
    background: transparent;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: .75rem;
    border-radius: 20px;
    margin: 0;
    text-transform: uppercase;
    text-decoration: none;
    padding: 9px 10px 7px;
    text-align: center;
    line-height: 1;
    border: 2px solid #000;
    color: #000;
    cursor: pointer;

    &:hover {
        background: #000;
        color: #fff000;
    }
}