ul.list-recap {
    list-style: none;
    text-align: center;
    margin: 0;
    padding: 0;
    li {
        text-align: center;
        display: inline-block;
        vertical-align: top;
        .recap-content {
            min-width: 180px;
            margin: 12px 0;
            .recap-title {
                margin-bottom: 6px;
            }
        }
    }
}