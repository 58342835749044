.vial-image {
    width: 50px;
    margin: 36px auto;
}

/* ul.lists {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
        padding-bottom: 100px;
    }
} */

.collection-title {
    margin-bottom: 24px;
}

.top-title, .top-subtitle {
    font-family: canada-type-gibson, sans-serif;
    font-style: normal;
    color: black;
    text-align: center;
    line-height: 1.4;
}

.top-title {
    font-size: 2.625rem;
    font-weight: 600;
}

.top-subtitle {
    font-size: 1.5rem;
    font-weight: 100;
    margin-bottom: 24px;
}

.sold-out-recap {
    text-align: center;
    margin: 32px 0 0 0;
}

.eth-address-wrapper {
    text-align: center;
    word-break: break-all;
    .eth-address {
        color: #fff;
        background: #000;
        display: inline-block;
        font-size: 1rem;
        font-weight: 600;
        padding: 10px 24px 5px 24px;
    }
}