.logo {
    width: 67px !important;
    height: 50px !important;
    img {
        height: 100%;
    }
}

.logo-wrapper {
    margin-top: -16px;
}

.loader {
    width: 22px;
    margin: 0 24px;
}

header {
    .topbar {

        margin-bottom: 80px;

        .button {
            width: 100%;
            box-sizing: border-box;
        }

        ul.social {
            margin: 0;
            padding: 0;
            list-style-type: none;
            li {
                float: left;
                width: 24px;
                height: 24px;
                margin-left: 6px;
                margin-top: 6px
            }
        }
    }
}

.small-top-lines {
    height: 16px;
    margin: 16px 0;
    &.dark {
        border-left: 1px solid #000;
        border-right: 1px solid #000;
    }
}