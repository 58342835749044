.mint-progress {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 0 5% 0;

    .label {
        margin-bottom: 5px;
    }

    .progress {
        position: relative;
        width: 55%;
        height: 5px;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 3px;

        .percentage {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 0%;
            height: 100%;
            border-radius: 3px;
            background-color: black;
        }
    }
}