.actions {
    width: 175px;
    margin: 24px auto 0 auto;
    .input-number {

        margin-bottom: 15px;
        height: 36px;
        width: 100%;
        text-align: center;

        .decrement, .increment {
            margin: 0;
            padding: 0;
            background-color: transparent;
            background-size: contain;
            cursor: pointer;
            width: 36px;
            height: 36px;
            border-radius: 30px;
            border: 2px solid #000;
            &:hover {
                background-color: #000;
            }
            &.disabled {
                cursor: default;
                opacity: .25;
            }
            &.disabled:hover {
                background-color: transparent;
            }
        }
        .decrement {
            float: left;
            background-image: url('../../../public/img/minus.png');
            &:hover {
                background-image: url('../../../public/img/minus_yellow.png');
            }
            &.disabled:hover {
                background-image: url('../../../public/img/minus.png');
            }
        }
        .increment {
            float: right;
            background-image: url('../../../public/img/plus.png');
            &:hover {
                background-image: url('../../../public/img/plus_yellow.png');
            }
            &.disabled:hover {
                background-image: url('../../../public/img/plus.png');
            }
        }
        span {
            font-family: canada-type-gibson, sans-serif;
            font-weight: 600;
            font-style: normal;
            font-size: 24px;
            line-height: 40px;
        }
    }
    .button {
        width: 100%;
        box-sizing: border-box;
        font-size: 1rem;
        padding: 10px 10px 6px;
        border-radius: 30px;
    }
    .minted {
        font-size: 1rem;
        padding: 10px 10px 6px;
        text-transform: uppercase;
        font-weight: 600;
        background: black;
        color: white;
    }
}
.tx-output {
    padding: 12px 24px;
    word-break: break-all;
    font-size: 12px;
    font-weight: 400;
}