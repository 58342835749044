.stage {
    padding: 36px 20px;
    text-align: center;
    &.active {
        border: 2px solid #000;
    }
}

.stage-header {
    .small-top-lines {
        height: 48px;
        text-align: center;
    }
    .progress {
        display: inline-block;
        padding: 6px;
        p { margin-bottom: 0; }
    }
    .countdown {
        font-size: 1.5rem;
        line-height: 42px;
    }
    .divider {
        width: 48px;
        border-bottom: 1px solid #000;
        margin: 0 auto;
    }
}
