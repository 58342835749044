ul.list {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
        padding-bottom: 32px;
        border-bottom: 1px solid #000;
        margin-bottom: 36px;
        .minted {
            margin-bottom: 28px;
        }
        h3 {
            margin-top: 0;
        }
        .comment {
            font-size: 1rem;
            line-height: 1.5;
            margin: 12px 0;
        }
        .list-sold-out {
            margin-bottom: 0;
            font-weight: 600;
            background: #000;
            color: #FFF;
            display: inline-block;
            padding: 6px 18px 4px 18px;
            border-radius: 24px;
        }
    }
    li:last-child {
        padding-bottom: 0px;
        margin-bottom: 0px;
        border-bottom: none;
    }
    li.unavailable {
        opacity: .25;
    }
}