@import url("https://use.typekit.net/csw6hap.css");
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #fff000;
    font-family: Arial, Helvetica, sans-serif;
}

#root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

body, p {
    font-family: canada-type-gibson, sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 200;
    color: black;
}

button, .text {
    font-family: canada-type-gibson, sans-serif;
    font-weight: 100;
    font-style: normal;
    color: black;
}

.colophon p, small {
    font-size: 9px;
}
h1, h2, h3, h4, .h1, .h2, .h3, .h4 {

    font-family: canada-type-gibson, sans-serif;
    font-weight: 100;
    font-style: normal;
    color: black;
}

h1, .h1 {
    font-size: 2.625rem;
    margin: 0;
}

b, strong, .strong {
    font-weight: 600 !important;
}


.light {
    font-weight: 300;
}

.italic {
    font-style: italic;
}

a {
    color: black;
    text-decoration: underline;
}
a:hover {
    color: black;
    text-decoration: none;
}

.strikethrough {
    text-decoration: line-through;
}

.uk-alert {
    color: red;
    background-color: white;
    padding: 32px;
    .uk-alert-close {
        color: red;
        top: 16px;
        right: 16px;
    }
    p {
        font-size: 15px;
        line-height: 20px;
        font-weight: 200;
        max-width: 512px;
        margin: 0 auto;
        text-align: center;
    }
}

.uk-container {
    max-width: 1152px;
    padding: 0 32px;
    box-sizing: border-box;
}

*+.uk-grid-margin-medium, .uk-grid+.uk-grid-medium, .uk-grid+.uk-grid-row-medium, .uk-grid-medium>.uk-grid-margin, .uk-grid-row-medium>.uk-grid-margin {
    margin-top: 32px;
}

.uk-grid-medium {
    margin-left: -64px;
}

.uk-grid-medium>* {
    padding-left: 64px;
}

.uk-grid-column-small>*, .uk-grid-small>* {
    padding-left: 32px;
}

.uk-grid-column-small, .uk-grid-small {
    margin-left: -32px;
}